<template>
    <div class="requirements full-width" v-if="translates && translates[langUrl]">
        <div
            class="requirements__main-image">
            <div class="requirements__main-image__opacity"></div>
            <div class="requirements__main-image__container">
                <div class="requirements__main-image__container__text">
                    <div class="requirements__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
                <div class="requirements__main-image__container__directions">
                    <div
                        class="requirements__main-image__container__direction"
                        @click="goTo('operator')"
                    >{{ translates['/operator'].pageNames_main[lang] }}</div>
                    <div
                        class="requirements__main-image__container__direction active"
                        @click="goTo('requirements')"
                    >{{ translates['/operator'].pageNames_requirements[lang] }}</div>
                    <div
                        class="requirements__main-image__container__direction"
                        @click="goTo('operator-questions')"
                    >{{ translates['/operator'].pageNames_questions[lang] }}</div>
                </div>
            </div>
        </div>
        <div class="requirements__main-container">
            <div class="requirements__create">
                <div class="requirements__create__title">{{ translates[langUrl].create_title[lang] }}</div>
                <div v-if="isDesktop" class="requirements__create__container">
                    <div class="requirements__create__text" v-html="translates[langUrl].create_text[lang]"></div>
                    <div class="requirements__create__picture">
                        <div v-if="isDesktop" class="requirements__create__text-block">
                            <div class="requirements__create__text-block__text">{{ translates[langUrl].create_picture_text[lang] }}</div>
                            <BaseButton
                                style="width: 100%"
                                class="button-fill"
                                @click="authorization"
                            >{{ translates[langUrl].button_authorization[lang] }}</BaseButton>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="requirements__create__text" v-html="translates[langUrl].create_text[lang]"></div>
                    <div class="requirements__create__picture_mobile"></div>
                    <div class="requirements__create__text-block_mobile">
                        <div class="requirements__create__text-block__text">{{ translates[langUrl].create_picture_text[lang] }}</div>
                        <BaseButton
                            style="width: 100%"
                            class="button-fill"
                            @click="authorization"
                        >{{ translates[langUrl].button_authorization[lang] }}</BaseButton>
                    </div>
                </div>
            </div>
        </div>
        <div class="requirements__group__container">
            <div class="requirements__group">
                <div class="requirements__group__picture-container">
                    <div class="requirements__group__picture"></div>
                </div>
                <div class="requirements__group__text-block">
                    <div class="requirements__group__text-block__text">
                        <div class="requirements__group__text-block__title">{{ translates[langUrl].group_title[lang] }}</div>
                        <ul v-html="translates[langUrl].group_text[lang]"></ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="requirements__pink-block">
            <div class="requirements__pink-block__icon"></div>
            <div class="requirements__pink-block__comment">{{ translates[langUrl].pinkBlock_comment[lang] }}</div>
            <div class="requirements__pink-block__text">{{ translates[langUrl].pinkBlock_title[lang] }}</div>
        </div>
        <div class="requirements__form-block">
            <div v-if="isDesktop" class="requirements__form-block__container">
                <div class="requirements__form-block__picture"></div>
                <FormConnection
                    :title='translates[langUrl].formConnection_title[lang]'
                />
            </div>
            <div v-else>
                <div class="requirements__form-block__picture_mobile"></div>
                <FormConnection
                    :title='translates[langUrl].formConnection_title[lang]'
                />
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    import BaseButton from '../../components/common/BaseButton.vue';
    import FormConnection from '../../components/common/FormConnection.vue';
    import MobilePreview from '../../components/common/trip/PreviewCard';

    import moment from 'moment';
    moment.locale('ru');

    export default {
        name: 'Requirements',
        // metaInfo: {
        //     title: 'Требования к операторам'
        // },
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            FormConnection,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            tourSplideCounter: 1,
            header: 0,
            langUrl: '/operator/requirements'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            this.getTrips();
            this.isDesktop = !isMobile();
            this.tourSplideCounter = SplideSlideCounter(350, 1280);
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
        },
    };
</script>

<style lang="scss">
.requirements {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../../assets/operators/Header.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
            // background: url(../../assets/operators/mobile-header.jpg) no-repeat;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #000;
            opacity: 0.5;
        }
        &__container {
            position: absolute;
            width: 100%;
            height: 440px;
            display: flex;
            flex-direction: column;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 356px;
                margin-top: 110px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                margin: auto;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                }
            }
            &__directions {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                cursor: pointer;
                margin: 32px auto 43px;
                &::-webkit-scrollbar {
                    display: none;
                }
                @media all and (max-width: 768px) {
                    margin-top: 150px;
                    width: 100%;
                    margin: 110px auto 30px;
                }
            }
            &__direction {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                padding: 10px 24px;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                border-radius: 22px;
                margin-right: 8px;
                white-space: nowrap;
                @media all and (max-width: 768px) {
                    &:first-of-type {
                        margin-left: 20px;
                        padding: 8px 24px;
                    }
                }
                &.active {
                    background: #FFFFFF;
                    color: #3A4256;
                }
            }
        }
    }
    &__form-block {
        max-width: 1360px;
        margin: 80px auto;
        @media all and (max-width: 768px) {
            margin: 60px 0;
            padding: 0 20px;
        }
        &__title {
            max-width: 1280px;
            margin: 0 auto 45px;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 32px;
            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 34px;
                margin: 0 0 24px;
            }
        }
        &__container {  
            height: 640px;
            display: flex;
            justify-content: flex-end;
            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
                padding: 0 20px;
            }
        }
        &__picture {
            height: 640px;
            width: 804px;
            background: url(../../assets/operators/requirements/Form.jpg);
            // background-position-x: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 80px;
            position: relative;
            &_mobile {
                width: 100%;
                height: 222px;
                margin-bottom: 20px;
                background: url(../../assets/operators/requirements/Form.jpg);
                background-size: cover;
                border-radius: 12px;
            }
        }
        &__text-block {
            position: absolute;
            left: -70px;
            top: 32px;
            width: 400px;
            background: #FFFFFF;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            padding: 20px;
            &_mobile {
                margin-bottom: 20px;
                padding: 0 20px;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 20px;
                @media all and (max-width: 768px) { 
                    font-size: 24px;
                }
            }
            &__text {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #616D89;
                margin-bottom: 20px;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                }
            }
            &__description {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
            }
        }
    }
    &__create {
        max-width: 1360px;
        margin: 80px auto;
        @media all and (max-width: 768px) {
            margin: 60px 0;
            padding: 0 20px;
        }
        &__title {
            max-width: 1000px;
            margin: 0 auto 45px 0;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 32px;
            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 34px;
                margin: 0 0 24px;
            }
        }
        &__container {
            display: flex;
            justify-content: space-between;
            max-width: 1220px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
                padding: 0 20px;
            }
        }
        &__text {
            max-width: 500px;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #273155;
            margin-right: 120px;
            @media all and (max-width: 1200px) {
                margin-right: 0;
                padding: 20px;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                margin: 0 0 40px;
                font-size: 16px;
                line-height: 140%;
            }
        }
        &__picture {
            min-width: 600px;
            height: 430px;
            margin-top: 10px;
            background: url(../../assets/operators/Create.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            border-radius: 12px;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 355px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 290px;
            }
            &_mobile {
                width: 100%;
                height: 222px;
                margin-bottom: 20px;
                background: url(../../assets/operators/Create.jpg);
                background-size: cover;
                border-radius: 12px;
            }
        }
        &__text-block {
            position: absolute;
            right: -90px;
            top: 100px;
            width: 400px;
            background: #FFFFFF;
            box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            padding: 20px;
            @media all and (max-width: 1420px) {
                right: 20px;
            }
            &_mobile {
                margin-bottom: 20px;
                padding: 0 20px;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 20px;
            }
            &__text {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #616D89;
                margin-bottom: 20px;
            }
            &__description {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
            }
        }
    }
    &__pink-block {
        width: 100%;
        height: 420px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 375px;
            margin-bottom: 60px;
        }
        &__icon {
            width: 55px;
            height: 55px;
            background: url(../../assets/brand/icon.png);
            margin-bottom: 28px;
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__comment {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #FFFFFF;
            margin-bottom: 12px;
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 140%;
                margin-bottom: 20px;
            }
        }
        &__text {
            max-width: 1400px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;
            text-align: center;
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 130%;
                padding: 0 20px;
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
    }
    
    &__group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        padding: 60px 0 80px;
        max-width: 1260px;
        @media all and (max-width: 768px) {
            flex-direction: column-reverse;
            padding:60px 0;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__container {
            background: #F6F7F9;
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            width: 500px;
            @media all and (max-width: 1200px) {
                padding: 0 20px;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 12px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
                ul > li {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 160%;
                    color: #3A4256;
                    margin-bottom: 17px;
                }
            }
            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: #3A4256;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 18px;
                }
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
        &__picture-container {
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                margin: 0 auto;
            }
        }
        &__picture {
            min-width: 600px;
            height: 430px;
            background-size: cover;
            border-radius: 10px;
            background-image: url(../../assets/operators/requirements/Partner.jpg);
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 355px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 290px;
            }
            @media all and (max-width: 768px) {
                min-width: 100%;
                height: 240px;
            }
        }
        &__button {
            width: 600px;
            margin-top: 20px;
            height: 54px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__block-name {
        width: 1280px;
        margin: 80px auto 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #273155;

    }
}

</style>